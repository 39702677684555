import React from 'react'

const Exchanges = () => {
	return (
		<div>
			Exchanges
		</div>
	)
}

export default Exchanges
